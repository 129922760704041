<template>
  <section class="conAllSettings">
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <div class="headerSettingPage headerOfPage">Attendance Pages</div>
        </v-col>
      </v-row>
    </v-container>
    <section class="contentSettingPage mt-3">
      <v-container fluid>
        <v-row>
          <v-col lg="3" md="4" sm="6" cols="12" v-if="user.role_id == 1">
            <router-link :to="{ name: 'projectAttendance' }">
              <v-card
                class="mx-auto fill-height conCard"
                max-width="100%"
                elevation="0"
              >
                <div class="fill-height py-7 d-flex justify-center">
                  <div class="fill-height conOfCard">
                    <v-icon class="d-flex justify-center"
                      >mdi-file-outline</v-icon
                    >
                    <div class="conTxt">Project Attendance</div>
                  </div>
                </div>
              </v-card>
            </router-link>
          </v-col>

          <!-- <v-col lg="3" md="4" sm="6" cols="12">
            <router-link :to="{ name: 'adminMyattendance' }">
              <v-card
                class="mx-auto fill-height conCard"
                max-width="100%"
                elevation="0"
              >
                <div class="fill-height py-7 d-flex justify-center">
                  <div class="fill-height conOfCard">
                    <v-icon class="d-flex justify-center">mdi-pencil</v-icon>
                    <div class="conTxt">My Attendance</div>
                  </div>
                </div>
              </v-card>
            </router-link>
          </v-col> -->

          <v-col lg="3" md="4" sm="6" cols="12">
            <router-link :to="{ name: 'usersAttendance' }">
              <v-card
                class="mx-auto fill-height conCard"
                max-width="100%"
                elevation="0"
              >
                <div class="fill-height py-7 d-flex justify-center">
                  <div class="fill-height conOfCard">
                    <v-icon class="d-flex justify-center"
                      >mdi-account-box</v-icon
                    >
                    <div class="conTxt">Attendance</div>
                  </div>
                </div>
              </v-card>
            </router-link>
          </v-col>

          <v-col lg="3" md="4" sm="6" cols="12" v-if="user.role_id == 1">
            <router-link :to="{ name: 'userAttendancePerDay' }">
              <v-card
                class="mx-auto fill-height conCard"
                max-width="100%"
                elevation="0"
              >
                <div class="fill-height py-7 d-flex justify-center">
                  <div class="fill-height conOfCard">
                    <v-icon class="d-flex justify-center"
                      >mdi-calendar-edit</v-icon
                    >
                    <div class="conTxt">Users Attendance Ber Day</div>
                  </div>
                </div>
              </v-card>
            </router-link>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>
<style lang="scss" scoped>
@import "../TMpages/_adminpages.scss";
</style>
